import React from 'react';
import styled from 'react-emotion';

import Link from 'components/Link';

const LogoWrapStyled = styled('div')`
  position: relative;
  flex-grow: 1;
`;

const LogoStyled = styled(Link)`
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
`;

export default function Logo({ onClick }) {
  return (
    <LogoWrapStyled>
      <LogoStyled onClick={() => onClick('/')}>hazel brugger</LogoStyled>
    </LogoWrapStyled>
  );
}
