import styled, { css } from 'react-emotion';
import media from 'utils/mediaqueries';
import Wrapper from 'components/Wrapper';
import ArrowBack from 'components/ArrowBack';
import { Column } from 'components/Grid';
import { sizes, colors } from 'components/Layout/variables';

export const NavbarStyled = styled('div')(
  {
    position: 'fixed',
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    width: '100%',
    maxWidth: '100vw',
    height: '100vh',
    pointerEvents: 'none',
    zIndex: 200,
    backgroundColor: 'transparent',

    '&::before': {
      position: 'absolute',
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      content: '""',
      padding: sizes.spacingQuarter,
      backgroundColor: 'transparent',
      backgroundClip: 'content-box',

      [media('md')]: {
        padding: sizes.spacing,
      },
    },
  },
  // Active navbar.
  (props) =>
    props.active && {
      backgroundColor: 'white',

      '&::before': {
        backgroundColor: colors.yellow,
      },
    },
);

export const NavbarWrapperStyled = styled(Wrapper)(
  {
    position: 'relative',
    height: '100%',
    transition: 'padding 0.15s',
    color: 'white',
  },
  (props) =>
    props.active && {
      color: colors.grey,
    },
  (props) =>
    props.compact && {
      // Set smaller top padding for subpages.
      [media('md')]: {
        paddingTop: `${sizes.spacing} !important;`,
      },
    },
  (props) =>
    props.sticky &&
    !props.active && {
      // Set smaller top padding for sticky Navbar when menu is not open.
      color: colors.grey,
      [media('md')]: {
        paddingTop: `${sizes.spacingQuarter} !important;`,
      },
    },
);

export const NavbarInnerStyled = styled('div')({
  position: 'relative',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: `0 ${sizes.spacingQuarter}`,

  [media('md')]: {
    padding: `0 ${sizes.spacing}`,
  },

  [media('xl')]: {
    padding: 0,
  },

  // Empty pseudo element to trick flexbox into flexing it.
  '&::after': {
    [media('md')]: {
      content: '""',
    },
  },
});

export const ArrowBackStyled = styled(ArrowBack)({
  marginRight: 'auto',
});

export const PersistentWrapperStyled = styled('div')(
  {
    flex: '0 0 auto',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pointerEvents: 'all',
    padding: `${sizes.spacingHalf} 0`,

    // Background when sticky.
    '&::before': {
      display: 'block',
      position: 'fixed',
      content: '""',
      left: 0,
      top: 0,
      width: '100%',
      height: 70,
      backfaceVisibility: 'hidden',
      backgroundColor: 'transparent',

      [media('md')]: {
        height: 80,
      },
    },
  },
  (props) =>
    props.sticky &&
    !props.active && {
      '&::before': {
        transition: 'background-color 0.35s',
        backgroundColor: colors.white,
        transform: 'translate(0, 0)',
      },
    },
);

export const TransitoryWrapperStyled = styled('div')(
  {
    flex: '0 1 auto',
    position: 'relative',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    padding: `${sizes.spacingHalf} 0`,
    pointerEvents: 'none',
    opacity: 0,
  },
  (props) =>
    props.visible && {
      pointerEvents: 'all',
      opacity: 1,
    },
);

export const ColumnStyled = styled(Column)({
  marginBottom: sizes.spacing,
});

export const ColumnHiddenTabletStyled = styled(ColumnStyled)({
  [media('md')]: {
    display: 'none',
  },
  [media('xxl')]: {
    display: 'block',
  },
});
