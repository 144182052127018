import React, { memo } from 'react';

import { Grid, Row } from 'components/Grid';
import Logo from 'components/Logo';
import Burger from 'components/Burger';
import Headline from 'components/Headline';
import NavigationMain from 'components/NavigationMain';
import NavigationSocial from 'components/NavigationSocial';
import EventsUpcoming from 'components/EventsUpcoming';
import InfoBooking from 'components/InfoBooking';
import {
  NavbarWrapperStyled,
  NavbarInnerStyled,
  ArrowBackStyled,
  PersistentWrapperStyled,
  TransitoryWrapperStyled,
  ColumnStyled,
  ColumnHiddenTabletStyled,
} from './styles';

const NavbarSticky = ({
  menuOpen,
  sticky,
  pathname,
  onArrowBackClick,
  onBurgerClick,
  onNavbarLinkClick,
}) => (
  <NavbarWrapperStyled
    active={menuOpen}
    sticky={sticky}
    compact={pathname !== '/'}
  >
    <NavbarInnerStyled>
      <PersistentWrapperStyled active={menuOpen} sticky={sticky}>
        {pathname !== '/' ? (
          <ArrowBackStyled onClick={onArrowBackClick}>Back</ArrowBackStyled>
        ) : (
          <>
            <Logo onClick={onNavbarLinkClick} />
            <Burger onClick={onBurgerClick} active={menuOpen} />
          </>
        )}
      </PersistentWrapperStyled>
      <TransitoryWrapperStyled visible={menuOpen}>
        <Grid>
          <Row>
            <ColumnStyled width={{ sm: '50%', lg: '35%', xxl: '25%' }}>
              <Headline element="h5">00 – Navigation</Headline>
              <NavigationMain onClick={onNavbarLinkClick} />
            </ColumnStyled>
            <ColumnStyled width={{ sm: '50%', lg: '35%', xxl: '25%' }}>
              <Headline element="h5">01 – Booking</Headline>
              <InfoBooking />
            </ColumnStyled>
            <ColumnStyled width={{ sm: '100%', lg: '30%', xxl: '20%' }}>
              <Headline element="h5">02 – Social Media</Headline>
              <NavigationSocial />
            </ColumnStyled>
            <ColumnHiddenTabletStyled width={{ sm: '100%', xxl: '30%' }}>
              <Headline element="h5">03 – Anstehende Termine</Headline>
              <EventsUpcoming limit={3} />
            </ColumnHiddenTabletStyled>
          </Row>
        </Grid>
      </TransitoryWrapperStyled>
    </NavbarInnerStyled>
  </NavbarWrapperStyled>
);

/**
 * Wrapping the component into a memo function.
 * Only updating it if the sticky property changes
 * to avoid triggering rerender on every scroll.
 */
const NavbarStickyMemo = memo(NavbarSticky, (oldProps, newProps) => {
  if (oldProps.sticky !== newProps.sticky) return false;
  if (oldProps.menuOpen !== newProps.menuOpen) return false;
  if (oldProps.pathname !== newProps.pathname) return false;

  return true;
});

export default NavbarStickyMemo;
