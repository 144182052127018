import React from 'react';
import { SvgStyled } from 'components/Icon/styles';

export default () => (
  <SvgStyled
    xmlns="http://www.w3.org/2000/svg"
    width="31.8"
    height="31.8"
    viewBox="0 0 31.8 31.8"
  >
    <path
      d="M31.8,15.9A15.9,15.9,0,1,1,21.2.9a15.87,15.87,0,0,1,10.6,15Z"
      fill="#fff"
    />
    <path
      d="M30.8,10.6H.9A15.94,15.94,0,0,1,15.9,0,15.71,15.71,0,0,1,30.8,10.6Z"
      fill="red"
    />
    <path
      d="M30.9,21.2a15.9,15.9,0,0,1-20.3,9.7A16.06,16.06,0,0,1,.9,21.2Z"
      fill="#009eff"
    />
  </SvgStyled>
);
