import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import scrollToElement from 'scroll-to-element';
import { NavigationMetaWrapper, LinkStyled, ButtonStyled } from './styles';

const NavigationMeta = props => {
  const { items } = props;

  if (!items || items.length <= 0) {
    return null;
  }

  return (
    <NavigationMetaWrapper>
      <ButtonStyled
        onClick={() => {
          scrollToElement('header', {
            align: 'top',
            duration: 300,
            offset: 0,
          });
        }}
      >
        Nach oben scrollen
      </ButtonStyled>
      {items.map(item => (
        <LinkStyled key={item.slug} to={item.fullUri}>
          {item.title}
        </LinkStyled>
      ))}
    </NavigationMetaWrapper>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query NavigationMetaQuery {
        craft {
          entries(section: pages) {
            ... on Craft_Pages {
              id
              title
              slug
              fullUri
            }
          }
        }
      }
    `}
    render={data => <NavigationMeta items={data.craft.entries} />}
  />
);
