import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { parseAsContent } from 'utils/parser';
import {
  CookieInfoStyled,
  ContainerStyled,
  ContentStyled,
  ButtonStyled,
} from './styles';

class CookieInfo extends Component {
  /**
   * Initial state.
   */
  state = {
    acceptedCookies: undefined,
  };

  /**
   * Component did mount.
   */
  componentDidMount = () => {
    this.setState({
      acceptedCookies: cookie.load('accepted_cookies') === 'true',
    });
  };

  /**
   * Event:
   * Handle accept cookies
   */
  handleAcceptCookiesClick = () => {
    const expires = new Date();
    const now = new Date();
    expires.setDate(now.getDate() + 14);

    cookie.save('accepted_cookies', true, { expires, path: '/' });
    this.setState({ acceptedCookies: true });
  };

  /**
   * Render.
   */
  render() {
    const { acceptedCookies } = this.state;
    const { content } = this.props;

    // Only show cookie info when cookies got loaded
    // and the value is actually "false".
    if (acceptedCookies === false) {
      return (
        <CookieInfoStyled>
          <ContainerStyled>
            <ContentStyled>{parseAsContent(content)}</ContentStyled>
            <ButtonStyled onClick={this.handleAcceptCookiesClick}>
              Akzeptieren
            </ButtonStyled>
          </ContainerStyled>
        </CookieInfoStyled>
      );
    }

    return null;
  }
}

CookieInfo.propTypes = {
  content: PropTypes.string,
};

CookieInfo.defaultProps = {
  content: null,
};

export default () => (
  <StaticQuery
    query={graphql`
      query CookieInfoQuery {
        craft {
          globals {
            dataPrivacy {
              dataPrivacyText {
                content
              }
            }
          }
        }
      }
    `}
    render={data => (
      <CookieInfo
        content={data?.craft?.globals?.dataPrivacy?.dataPrivacyText?.content}
      />
    )}
  />
);
