import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  TileStyled,
  WrapperStyled,
  WrapperDetailsStyled,
  DateStyled,
  DayStyled,
  MonthStyled,
  TitleStyled,
  VenueStyled,
  TicketArrowStyled,
  LinkStyled,
} from './styles';

const Tile = (props) => {
  const { id, eventDate, eventVenue, eventTicketLink, eventProgram } = props;

  // Format date values.
  const eventDateMoment = moment.unix(eventDate);
  const eventDateFormatted = eventDateMoment.format('YYYY-MM-DD @ HH:mm');
  const eventDateMonth = eventDateMoment.format('MMM');
  const eventDateDay = eventDateMoment.format('DD');

  return (
    <TileStyled>
      <WrapperDetailsStyled>
        {eventDate && (
          <DateStyled title={eventDateFormatted}>
            <DayStyled>{eventDateDay}</DayStyled>
            <MonthStyled>{eventDateMonth}</MonthStyled>
          </DateStyled>
        )}
        <div>
          <TitleStyled>
            {eventProgram.map((program) => program.title).join(', ')}
          </TitleStyled>
          {eventVenue?.[0] && (
            <VenueStyled>
              {eventVenue[0].title}, {eventVenue[0].eventVenueAddress?.city}
            </VenueStyled>
          )}
        </div>
      </WrapperDetailsStyled>

      {eventTicketLink?.url && (
        <>
          <WrapperStyled>
            <TicketArrowStyled />
          </WrapperStyled>
          <LinkStyled
            to={eventTicketLink.url}
            target={eventTicketLink.target}
          />
        </>
      )}
    </TileStyled>
  );
};

Tile.propTypes = {
  id: PropTypes.number.isRequired,
  eventDate: PropTypes.string.isRequired,
  eventVenue: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      eventVenueAddress: PropTypes.shape({
        city: PropTypes.string,
      }),
    }),
  ),
  eventTicketLink: PropTypes.shape({
    url: PropTypes.string,
  }),
  eventProgram: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ).isRequired,
};

Tile.defaultProps = {
  eventTicketLink: null,
};

export default Tile;
