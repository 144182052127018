import React from 'react';
import SwitchTheme from 'components/SwitchTheme';
import { BlockquoteStyled, FullBackgroundWrapStyled } from './styles';

const Blockquote = ({ cite, theme, children }) => (
  <SwitchTheme name={theme}>
    <FullBackgroundWrapStyled>
      <BlockquoteStyled cite={cite}>{children}</BlockquoteStyled>
    </FullBackgroundWrapStyled>
  </SwitchTheme>
);

export default Blockquote;
