import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
  StyledH6,
} from './styles';

export default function Headline(props) {
  const { element, children, className, numbered } = props;

  switch (element) {
    case 'h1':
      return (
        <StyledH1 className={className} numbered={numbered}>
          {children}
        </StyledH1>
      );
    case 'h2':
      return (
        <StyledH2 className={className} numbered={numbered}>
          {children}
        </StyledH2>
      );
    case 'h3':
      return (
        <StyledH3 className={className} numbered={numbered}>
          {children}
        </StyledH3>
      );
    case 'h4':
      return (
        <StyledH4 className={className} numbered={numbered}>
          {children}
        </StyledH4>
      );
    case 'h5':
      return (
        <StyledH5 className={className} numbered={numbered}>
          {children}
        </StyledH5>
      );
    case 'h6':
      return (
        <StyledH6 className={className} numbered={numbered}>
          {children}
        </StyledH6>
      );
    default:
      return null;
  }
}

Headline.propTypes = {
  element: PropTypes.string,
  className: PropTypes.string,
  numbered: PropTypes.bool,
};

Headline.defaultProps = {
  element: 'h3',
  numbered: false,
};
