import React from 'react';
import media from 'utils/mediaqueries';
import styled from 'react-emotion';
import { fonts, sizes } from 'components/Layout/variables';

export const BlockquoteStyled = styled('blockquote')(
  {
    position: 'relative',
    lineHeight: 1.35,
    margin: `${sizes.spacingHalf} 0`,
    padding: `${sizes.spacingDouble} ${sizes.spacingHalf} ${sizes.spacing}`,
    fontSize: fonts.sizes.base,

    [media('sm')]: {
      fontSize: fonts.sizes.h4,
      padding: `${sizes.spacingHalfDouble} ${sizes.spacingQuarter} ${
        sizes.spacing
      } ${sizes.spacingHalfDouble}`,
    },

    [media('md')]: {
      margin: `${sizes.spacingHalf} 0 ${sizes.spacingDouble}`,
    },

    [media('lg')]: {
      fontSize: fonts.sizes.large,
      padding: `${sizes.spacingDouble} 0 ${sizes.spacingHalfDouble} ${
        sizes.spacingDouble
      }`,
    },

    '&::before': {
      position: 'absolute',
      content: '"»"',
      left: 0,
      top: 0,
      fontSize: '2.4em',
      letterSpacing: '0.025em',

      [media('sm')]: {
        left: 0,
      },
    },

    '&::after': {
      display: 'block',
      fontSize: fonts.sizes.small,
      content: 'attr(cite)',
      letterSpacing: '0.025em',
    },

    p: {
      margin: `0 0 ${sizes.spacingHalf}`,
    },
  },

  ({ theme }) => ({
    backgroundColor: theme.backgroundPrimary,
  }),
);

export const FullBackgroundWrapStyled = styled('div')(({ theme }) => ({
  position: 'relative',

  '&::after': {
    content: '""',
    background: theme.backgroundPrimary,
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '-99999px',
    right: '-99999px',
    height: '100%',
    zIndex: '-1',
  },
}));
