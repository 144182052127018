import styled from 'react-emotion';
import posed from 'react-pose';

export const RouteContainer = posed.div({
  entering: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: { opacity: { ease: 'easeIn', duration: 100 } },
  },
  exit: {
    opacity: 0,
    transition: { opacity: { ease: 'easeOut', duration: 100 } },
  },
});

export const StyledRouteContainer = styled(RouteContainer)`
  position: relative;
  margin: 0 auto;
  width: 100%;
  counter-reset: headline-counter;
  overflow-x: hidden;
`;
