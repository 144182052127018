import styled from 'react-emotion';
import Link from 'components/Link';
import media from 'utils/mediaqueries';
import { fonts, sizes } from 'components/Layout/variables';

/**
 * Tile.
 */
export const TileStyled = styled('div')(
  {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `${sizes.spacingQuarter} ${sizes.spacingQuarter} ${
      sizes.spacingHalf
    } 0`,
    lineHeight: 1.5,
  },
  props => ({
    borderColor: props.hightlightColor,
  }),
);

/**
 * Wrapper.
 * Giving the inner sections some form.
 */
export const WrapperStyled = styled('div')(
  {
    flex: 'none',
  },
  props => ({
    [media('lg')]: {
      textAlign: props.textAlign ? props.textAlign : 'inherit',
      width: props.width ? props.width : 'initial',
      flex: props.flex ? props.flex : 'none',
    },
  }),
);

export const WrapperDetailsStyled = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  lineHeight: 1.3,
});

export const WrapperTicketsStyled = styled('div')({});

/**
 * Date.
 */
export const DateStyled = styled('div')({
  display: 'block',
  lineHeight: 1.1,
  fontWeight: 700,
  fontSize: 21,
  width: 30,
  marginRight: sizes.spacingHalf,
  textAlign: 'center',
});

export const DayStyled = styled('span')({
  display: 'block',
});

export const MonthStyled = styled('span')({
  display: 'block',
  textTransform: 'uppercase',
  fontSize: '.44em',
  letterSpacing: '.16em',
});

/**
 * Title.
 */
export const TitleStyled = styled('div')({
  fontWeight: '700',
  marginBottom: 4,
});

/**
 * Venue.
 */
export const VenueStyled = styled('div')({
  fontWeight: '700',
  fontSize: fonts.sizes.smaller,
  opacity: 0.6,
});

/**
 * Arrow.
 */
export const TicketArrowStyled = styled('span')({
  position: 'relative',
  display: 'inline-block',
  width: 10,
  height: 15,
  transition: 'transform .25s',

  [`${TileStyled}:hover &`]: {
    transform: 'translate(5px)',
  },

  '&::before, &::after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    width: 10,
    height: 2,
    right: 0,
    borderRadius: 1,
    backgroundColor: 'currentColor',
  },

  '&::before': {
    top: 5,
    transform: 'rotate(45deg) translate(0, -100%)',
  },

  '&::after': {
    bottom: 5,
    transform: 'rotate(-45deg) translate(0, 100%)',
  },
});

/**
 * Link.
 */
export const LinkStyled = styled(Link)({
  position: 'absolute',
  display: 'block',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,

  '&:focus, &:active': {
    WebkitTapHighlightColor: 'transparent',
  },
});
