import React from 'react';
import styled, { css } from 'react-emotion';
import Link from 'components/Link';
import media from 'utils/mediaqueries';
import { fonts, sizes } from 'components/Layout/variables';

export const NavSocialStyled = styled('nav')`
  ${(props) =>
    props.icon
      ? css`
          margin: 0 -${sizes.spacingHalf};
        `
      : null};
`;

// Format: icons
export const IconLinkStyles = css({
  display: 'inline-block',
  fontSize: '1.8em',
  padding: `${sizes.spacingQuarter} ${sizes.spacingHalf}`,

  [media('md')]: {
    fontSize: '1.1em',
  },

  '&:hover, &:focus, &:active': {
    transform: 'translateY(-5px)',
    outline: '0',
    webkitTapHighlightColor: 'transparent',
  },
});

// Format: default (text)
export const LinkStyled = styled(Link)`
  display: block;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  padding: ${sizes.spacingQuarter} 0 ${sizes.spacingHalf};
  transition: transform 0.25s ease-in-out;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    transform: translateX(15px);
    outline: 0;
    -webkit-tap-highlight-color: transparent;
  }

  ${(props) => (props.icon ? IconLinkStyles : null)};
`;
