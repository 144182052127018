import React from 'react';
import { SvgStyled } from 'components/Icon/styles';

export default () => (
  <SvgStyled
    xmlns="http://www.w3.org/2000/svg"
    width="31.8"
    height="31.8"
    viewBox="0 0 31.8 31.8"
  >
    <path
      d="M31.8,15.9A15.9,15.9,0,1,1,0,15.9a15.12,15.12,0,0,1,.9-5.3,15.913,15.913,0,0,1,30,0A17.822,17.822,0,0,1,31.8,15.9Z"
      fill="#e03131"
    />
    <path
      d="M31.8,15.9a15.951,15.951,0,0,1-.9,5.3H.9A15.951,15.951,0,0,1,0,15.9a15.12,15.12,0,0,1,.9-5.3H30.8A15.14,15.14,0,0,1,31.8,15.9Z"
      fill="#fff"
    />
  </SvgStyled>
);
