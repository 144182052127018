import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { filterItemsFromToday } from 'utils/date';
import Tile from './Tile';

class EventsUpcoming extends Component {
  render() {
    const { limit } = this.props;
    let { items } = this.props;

    // Filter items starting today.
    items = filterItemsFromToday(items, 'eventDate');

    // Limit number of items if necessary.
    items = items ? items.slice(0, limit) : [];

    // Render items.
    return items.length > 0 ? (
      items.map(item => <Tile key={item.id} {...item} />)
    ) : (
      <p>Keine Veranstaltungen</p>
    );
  }
}

EventsUpcoming.propTypes = {
  items: PropTypes.array,
  limit: PropTypes.number,
};

EventsUpcoming.defaultProps = {
  items: [],
  limit: 5,
};

export default props => (
  <StaticQuery
    query={graphql`
      query EventsUpcomingQuery {
        craft {
          events: entries(section: events, orderBy: "eventDate ASC") {
            id
            ... on Craft_EventsEvent {
              eventDate
              eventVenue {
                title
                eventVenueAddress {
                  city
                }
              }
              eventTicketLink {
                url
                target
              }
              eventProgram {
                title
              }
            }
          }
        }
      }
    `}
    render={data => <EventsUpcoming items={data.craft.events} {...props} />}
  />
);
