export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1920,
};

export const fonts = {
  primary: '"HK Grotesk", sans-serif',
  sizes: {
    mobile: '16px',
    base: '18px',
    small: '14px',
    smaller: '12px',
    large: '34px',
    h1: '3.2em',
    h1Mobile: '2.6em',
    h2: '3.8em',
    h2Mobile: '2.6em',
    h3: '2.35em',
    h3Mobile: '1.7em',
    h4: '1.4em', // title
    h5: '14px', // subtext
    h6: '10px',
  },
};

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  grey: '#3E3E3E',
  greyLight: '#F6F6F6',
  greyLightAlt: '#9E9E9E',
  greyMedium: '#6F6E6E',
  greyMediumAlt: '#707070',
  greyDark: '#272727',

  // Primary colors
  yellow: '#FFEAA5',
  yellowFaded: '#FFFAEC',
  yellowDark: '#E2B111',
  mint: '#99DDCC',
  green: '#8CD77C',
  greenFaded: '#EBF8F5',
  red: '#E57474',
  redFaded: '#F9E2E2',

  // Secondary colors
  blue: '#EBFBFB',
  blueFaded: '#F8FEFE',
  pink: '#FFE2E2',
  pinkFaded: '#fff5f5',
};

export const sizes = {
  spacingQuarter: '8px',
  spacingHalf: '16px',
  spacingQuarters: '24px',
  spacing: '32px', // Base value
  spacingHalfDouble: '48px',
  spacingDouble: '64px',
  spacingDoubleQuarter: '72px',
  spacingQuad: '128px',
  radius: '8px',
  radiusPrimary: '8px',
};
