import styled, { css } from 'react-emotion';
import { Link as GatsbyLink } from 'gatsby';

export const LinkStyled = css({
  boxSizing: 'border-box',
  display: 'inline-block',
  textDecoration: 'underline',
  textDecorationSkip: 'ink',
  color: 'currentColor',
  cursor: 'pointer',
});

export const GatsbyLinkStyled = styled(GatsbyLink)(LinkStyled);
export const ExternalLinkStyled = styled('a')(LinkStyled);
