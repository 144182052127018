import styled, { css } from 'react-emotion';
import media from 'utils/mediaqueries';
import { fonts, sizes } from 'components/Layout/variables';

export const NumberedStyles = css`
  counter-increment: headline-counter;

  &::before {
    display: inline;
    content: '0' counter(headline-counter) ' — ';
  }
`;

export const StyledH1 = styled('h1')`
  ${props => (props.numbered ? NumberedStyles : null)};
  font-size: ${fonts.sizes.h1Mobile};
  line-height: 1.2;

  ${[media('sm')]} {
    font-size: ${fonts.sizes.h1};
  }
`;

export const StyledH2 = styled('h2')`
  ${props => (props.numbered ? NumberedStyles : null)};
  font-size: ${fonts.sizes.h2Mobile};
  margin: ${sizes.spacingHalf} 0 ${sizes.spacingHalf};
  line-height: 1.2;

  ${[media('sm')]} {
    font-size: ${fonts.sizes.h2};
    margin: ${sizes.spacingHalf} 0 ${sizes.spacing};
  }
`;

export const StyledH3 = styled('h3')`
  ${props => (props.numbered ? NumberedStyles : null)}
  font-size: ${fonts.sizes.h3Mobile};
  margin: ${sizes.spacingHalf} 0 ${sizes.spacingHalf};

  ${[media('sm')]} {
    font-size: ${fonts.sizes.h3};
    margin: ${sizes.spacingHalf} 0 ${sizes.spacing};
  }
`;

export const StyledH4 = styled('h4')`
  ${props => (props.numbered ? NumberedStyles : null)}
  font-size: ${fonts.sizes.h4};
  margin: ${sizes.spacingHalf} 0 ${sizes.spacingHalf};

  ${[media('sm')]} {
    margin: ${sizes.spacing} 0 ${sizes.spacing};
  }
`;

export const StyledH5 = styled('h5')`
  font-size: ${fonts.sizes.h5};
  letter-spacing: 0.1em;
  margin: ${sizes.spacingQuarter} 0 ${sizes.spacingHalf};
  ${props => (props.numbered ? NumberedStyles : null)};
`;

export const StyledH6 = styled('h6')`
  font-size: ${fonts.sizes.h6};
  ${props => (props.numbered ? NumberedStyles : null)};
`;
