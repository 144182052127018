import React from 'react';
import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import { sizes } from 'components/Layout/variables';

export const GridStyled = styled('div')({
  position: 'relative',
});

export const RowStyled = styled('div')(
  {
    display: 'flex',
    flexWrap: 'wrap',

    [media('xl')]: {
      // flexWrap: 'nowrap',
    },
  },
  props => ({
    margin: `0 -${props.theme.grid.gutterWidthHalf}`,
  }),
);

export const ColumnStyled = styled('div')(
  {},
  props => ({
    display: props.display ? props.display : 'block',
    flexDirection: 'column',
    flexBasis: '100%',
    width: '100%',
    margin: `0 ${
      props.gutterWidth ? props.gutterWidth : props.theme.grid.gutterWidthHalf
    }`,
  }),

  // Map each available width, use media query where applicable.
  props =>
    props.mappedWidths.map(definition => ({
      [media(definition.breakpoint)]: {
        width: `calc(${definition.width} - ${props.theme.grid.gutterWidth})`,
        flexBasis: `calc(${definition.width} - ${
          props.theme.grid.gutterWidth
        })`,
      },
    })),
);
