const vars = require('components/Layout/variables');

module.exports = {
  colorPrimary: vars.colors.grey,
  // colorPrimaryHover
  // colorPrimaryActive
  // colorPrimaryDisabled
  backgroundPrimary: vars.colors.yellow,
  backgroundPrimaryHover: vars.colors.yellow,
  backgroundPrimaryActive: vars.colors.yellow,
  // backgroundPrimaryDisabled
};
