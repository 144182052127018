import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';

// Primary
import themeDefault from 'components/Layout/themes/default';
import themeGrey from 'components/Layout/themes/grey';
import themeRed from 'components/Layout/themes/red';
import themeRedFaded from 'components/Layout/themes/redFaded';
import themeMint from 'components/Layout/themes/mint';
import themeGreen from 'components/Layout/themes/green';
import themeGreenFaded from 'components/Layout/themes/greenFaded';
import themeYellow from 'components/Layout/themes/yellow';
import themeYellowFaded from 'components/Layout/themes/yellowFaded';

// Secondary
import themePink from 'components/Layout/themes/pink';
import themePinkFaded from 'components/Layout/themes/pinkFaded';
import themeBlue from 'components/Layout/themes/blue';
import themeBlueFaded from 'components/Layout/themes/blueFaded';

export default function SwitchTheme({ name, children }) {
  const themeType = {
    default: themeDefault,
    grey: themeGrey,
    red: themeRed,
    redFaded: themeRedFaded,
    mint: themeMint,
    green: themeGreen,
    greenFaded: themeGreenFaded,
    yellow: themeYellow,
    yellowFaded: themeYellowFaded,
    pink: themePink,
    pinkFaded: themePinkFaded,
    blue: themeBlue,
    blueFaded: themeBlueFaded,
  };

  const themeExists = name in themeType;

  return (
    <ThemeProvider theme={themeExists ? themeType[name] : themeDefault}>
      {children}
    </ThemeProvider>
  );
}

SwitchTheme.propTypes = {
  name: PropTypes.string,
};

SwitchTheme.defaultProps = {
  name: 'default',
};
