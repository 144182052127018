import styled from 'react-emotion';

export const ArrowBackStyled = styled('div')`
  cursor: pointer;
  padding: 10px 0;
`;

export const ArrowBackIconStyled = styled('div')`
  position: relative;
  flex: none;
  width: 24px;
  height: 14px;
`;

export const ArrowBackBarStyled = styled('span')`
  pointer-events: none;
  position: absolute;
  display: block;
  content: '';
  width: 22px;
  height: 2px;
  background-color: currentColor;
  transform-origin: 50% 50%;

  // Top bar
  &:nth-child(1) {
    width: 8px;
    top: 3px;
    transform: rotate(-45deg);
  }

  // Middle bar
  &:nth-child(2) {
    top: 5px;
    left: 2px;
  }

  // Bottom bar
  &:nth-child(3) {
    width: 8px;
    bottom: 5px;
    transform: rotate(45deg);
  }
`;
