import React from 'react';
import styled from 'react-emotion';

export const InfoCreditsStyled = styled('div')({
  fontSize: 'inherit',
  marginTop: 35,

  p: {
    margin: '0',
  },

  a: {
    textDecoration: 'none',
  },
});
