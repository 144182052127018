import React from 'react';
import striptags from 'striptags';
import HtmlToReact from 'html-to-react';
import Text from 'components/Text';
import Headline from 'components/Headline';
import Link from 'components/Link';
import Blockquote from 'components/Blockquote';
import ent from 'ent';

const tags = {
  basic: ['a', 'strong', 'b', 'em', 'i', 'br'],
  advanced: [
    'ul',
    'ol',
    'li',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'iframe',
    'blockquote',
  ],
  auxiliary: ['table', 'tbody', 'tr', 'td', 'th', 'caption'],
};

const allTags = [...tags.basic, ...tags.advanced, ...tags.pseudo];
const allTagsWithAuxiliary = [...allTags, ...tags.auxiliary];

export function limitCharacters(string, limit = 160, separator = ' ') {
  if (!string || string.length <= limit) return string;
  return string.substr(0, string.lastIndexOf(separator, limit));
}

function processContentWrapper(_, children) {
  return (
    <div key={0} data-cms>
      {children}
    </div>
  );
}

function processParagraphNode(node, children, index) {
  return (
    <Text key={index} element={node.name}>
      {children}
    </Text>
  );
}

function processBlockquoteNode(node, children, index) {
  return (
    <Blockquote key={index} element={node.name}>
      {children}
    </Blockquote>
  );
}

function processHeadlineNode(node, children, index) {
  const id = node.attribs.id || undefined;
  const name = node.attribs.name || undefined;
  return (
    <Headline key={index} id={id} name={name}>
      {children}
    </Headline>
  );
}

function processLinkNode(node, children, index) {
  const href = node.attribs.href || null;
  const target = node.attribs.target || null;
  const rel = node.attribs.rel || null;

  return (
    <Link key={index} to={href} target={target} rel={rel}>
      {children}
    </Link>
  );
}

function processBoldNode(node, children, index) {
  return <strong key={index}>{children}</strong>;
}

function processItalicNode(node, children, index) {
  return <em key={index}>{children}</em>;
}

const isValidNode = node =>
  ~tags.basic.indexOf(node.name) ||
  ~tags.advanced.indexOf(node.name) ||
  ~tags.auxiliary.indexOf(node.name) ||
  node.type === 'text';

function parseHtml(html) {
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  const processingInstructions = [
    {
      shouldProcessNode: node => node.name === 'cwrapper',
      processNode: processContentWrapper,
    },
    {
      shouldProcessNode: node => node.name === 'p',
      processNode: processParagraphNode,
    },
    {
      shouldProcessNode: node => node.name === 'blockquote',
      processNode: processBlockquoteNode,
    },
    {
      shouldProcessNode: node => node.name === 'a',
      processNode: processLinkNode,
    },
    {
      shouldProcessNode: node => node.name === 'b',
      processNode: processBoldNode,
    },
    {
      shouldProcessNode: node => node.name === 'strong',
      processNode: processBoldNode,
    },
    {
      shouldProcessNode: node => node.name === 'i',
      processNode: processItalicNode,
    },
    {
      shouldProcessNode: node => node.name && node.name.match(/h[1-6]/),
      processNode: processHeadlineNode,
    },
    {
      shouldProcessNode: isValidNode,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];
  const htmlToReactParser = new HtmlToReact.Parser(React);
  return htmlToReactParser.parseWithInstructions(
    `<cwrapper>${html}</cwrapper>`,
    () => true,
    processingInstructions,
  );
}

export function removeScripts(text) {
  return text.replace(/<script([\S\s]*?)<\/script>/gi, '');
}

export function removeDangerousTags(html) {
  html = removeScripts(html);
  return html;
}

export function parseAsText(html) {
  if (!html) return null;
  html = String(html);

  html = removeDangerousTags(html);
  const parsed = ent.decode(striptags(html));
  return parsed;
}

export function parseAsContent(html, { enableAuxiliaryTags = false } = {}) {
  if (!html) return null;

  html = String(html).replace(/(\r\n|\r|\n)/g, '');
  html = removeScripts(html);
  html = striptags(html, enableAuxiliaryTags ? allTagsWithAuxiliary : allTags);

  return parseHtml(html);
}
