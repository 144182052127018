import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyLinkStyled, ExternalLinkStyled } from './styles';

/**
 * Function:
 * Adjust backend url.
 *
 * @param string url
 */
const adjustBackendUrl = url => {
  // Skip adjustment if cms url isn't included.
  if (!url || url.indexOf(process.env.GATSBY_CMS_URL) === -1) {
    return url;
  }

  // Check if the link points to a file.
  const filename = url.split('/').pop();
  const isFile = /.+\..+/.test(filename);

  if (isFile) {
    return url;
  }

  // If it's a normal cms url, remove the cms link, so it's seen as internal link.
  return url.replace(process.env.GATSBY_CMS_URL, '');
};

/**
 * Component:
 * Link.
 */
const Link = ({ children, to, ...other }) => {
  // Check if link has click event handler
  const hasClickEvent = typeof other?.onClick === 'function';

  // If it's not a link
  if (!to && !hasClickEvent) {
    console.warn('Invalid target for a link on this page. Children:', children);
    return null;
  }

  // Adjust urls from backend.
  const toStripped = adjustBackendUrl(to);

  // Test for internal link.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(toStripped);

  // Use Gatsby <Link> for internal links.
  if (internal) {
    return (
      <GatsbyLinkStyled to={toStripped} {...other}>
        {children}
      </GatsbyLinkStyled>
    );
  }

  // Use <a> for links with click handler.
  if (hasClickEvent) {
    return <ExternalLinkStyled {...other}>{children}</ExternalLinkStyled>;
  }

  // Use <a> for external links.
  return (
    <ExternalLinkStyled
      href={toStripped}
      target="_blank"
      rel="noopener noreferrer"
      {...other}
    >
      {children}
    </ExternalLinkStyled>
  );
};

PropTypes.propTypes = {
  children: PropTypes.object,
  to: PropTypes.string,
};
PropTypes.defaultProps = {};

export default Link;
