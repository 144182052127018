import React from 'react';
import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import { colors, sizes } from 'components/Layout/variables';
import { Row, Column } from 'components/Grid';
import Headline from 'components/Headline';

const columnSpaceMobile = 20;

export const FooterStyled = styled('footer')(
  {
    position: 'relative',
    boxSizing: 'border-box',
    padding: '10px 8px 25px',

    [media('md')]: {
      padding: '0 32px',
    },

    a: {
      textDecoration: 'none',
    },
  },
  ({ theme }) => ({
    fontSize: theme.fonts.sizes.small,
    backgroundColor: colors.greyDark,
    color: colors.greyLight,
  }),
);

export const FooterHeadline = styled(Headline)({
  margin: '15px 0 15px',

  [media('md')]: {
    margin: '0 0 15px',
  },
});

export const NavigationSocialWrapperStyled = styled('div')({
  [media('md')]: {
    display: 'none',
  },
});

export const SpacingStyled = styled('hr')({
  position: 'relative',
  backgroundColor: 'transparent',
  display: 'block',
  height: 0,
  border: 0,
  margin: 0,
  padding: `${sizes.spacingHalf} 0`,
});
