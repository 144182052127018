import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import LinkButton from 'components/LinkButton';
import Wrapper from 'components/Wrapper';
import { colors, sizes } from 'components/Layout/variables';

export const CookieInfoStyled = styled(Wrapper)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100vw',
  height: 'auto',
  backgroundColor: colors.white,
  zIndex: 300,
  paddingTop: `${sizes.spacing} !important`,
  paddingBottom: `${sizes.spacing} !important`,
});

export const ContainerStyled = styled(Wrapper)({
  padding: `0 ${sizes.spacingQuarter}`,

  [media('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `0 ${sizes.spacing}`,
  },
});

export const ContentStyled = styled('div')({
  display: 'block',
  flex: 1,
  marginBottom: 20,

  [media('sm')]: {
    marginBottom: 0,
    marginRight: sizes.spacingDouble,
  },

  p: {
    margin: `${sizes.spacingHalf} 0`,

    [media('md')]: {
      margin: `${sizes.spacingQuarter} 0`,
    },
  },
});

export const ButtonStyled = styled(LinkButton)({
  cursor: 'pointer',
  flex: 'none',
});
