import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowBackStyled,
  ArrowBackBarStyled,
  ArrowBackIconStyled,
} from './styles';

export default function ArrowBack(props) {
  const { onClick, className } = props;

  return (
    <ArrowBackStyled onClick={onClick} className={className}>
      <ArrowBackIconStyled>
        <ArrowBackBarStyled />
        <ArrowBackBarStyled />
        <ArrowBackBarStyled />
      </ArrowBackIconStyled>
    </ArrowBackStyled>
  );
}

ArrowBack.propTypes = {
  onClick: PropTypes.func,
};

ArrowBack.defaultProps = {
  onClick: () =>
    console.log('Your back arrow seems to be missing a click handler.'),
};
