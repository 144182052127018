import React from 'react';
import styled from 'react-emotion';

export const IconStyled = styled('span')`
  display: inline-flex;
  flex: none;
`;

export const SvgStyled = styled('svg')`
  width: 1em;
  height: 1em;
  fill: currentColor;
`;
