import React from 'react';
import { SvgStyled } from 'components/Icon/styles';

export default () => (
  <SvgStyled xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10l0,0C20,4.5,15.5,0,10,0z M15.6,8.6c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4
	S15.6,6.4,15.6,8.6z M6.4,15.4h-2V4.6h2V15.4z"
    />
  </SvgStyled>
);
