import moment from 'moment';

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
const DATETIME_FORMAT_TODAY = 'YYYY-MM-DD 00:00';

/**
 * Filters items from today onwards, since we haven't
 * found an "easy" solution with GraphQL (directive or var/interpolation) yet
 *
 */
export function filterItemsFromToday(items, itemDateField) {
  const dateToday = moment().format(DATETIME_FORMAT_TODAY);

  if (items?.length) {
    return items.filter(
      e => moment.unix(e[itemDateField]).format(DATETIME_FORMAT) >= dateToday,
    );
  }

  return null;
}

export function groupItemsByDate(items, groupBy, itemDateField) {
  groupBy = groupBy || 'year';

  const groupingMethods = {
    month: {
      getGroupTitle: d => moment(d).format('MM-YYYY'),
      getGroupKey: d => moment(d).format('MM-YYYY'),
    },
    year: {
      getGroupTitle: d => moment(d).format('YYYY'),
      getGroupKey: d => moment(d).format('YYYY'),
    },
  };

  const dateGroups = !items
    ? {}
    : items.reduce((groupedItems, item, index) => {
        const itemDate = moment.unix(item[itemDateField]);
        const groupKey = groupingMethods[groupBy].getGroupKey(itemDate);

        if (!groupedItems.hasOwnProperty(groupKey)) {
          // create group from scratch
          groupedItems[groupKey] = {
            id: groupKey,
            title: groupKey,
            itemCount: 0,
            items: [],
          };
        }

        // push items
        groupedItems[groupKey].items.push(item);

        // add item count to group
        groupedItems[groupKey].itemCount++;

        return groupedItems;
      }, {});

  return dateGroups;
}
