import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { LinkStyled } from './styles';

const NavigationMain = (props) => {
  const { items, onClick } = props;

  if (!items || items.length <= 0) {
    return null;
  }

  return (
    <nav>
      {items.map((item) => {
        const title = item.moduleMenuTitle || item.title;
        return (
          <LinkStyled key={item.slug} onClick={() => onClick(`/#${item.slug}`)}>
            {title}
          </LinkStyled>
        );
      })}
    </nav>
  );
};

NavigationMain.propTypes = {
  onClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
};

NavigationMain.defaultProps = {
  items: [],
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query NavigationMainQuery {
        craft {
          entries(section: modules, moduleMenuHidden: false) {
            ... on Craft_ModulesModule {
              id
              title
              moduleMenuTitle
              slug
            }
          }
        }
      }
    `}
    render={(data) => <NavigationMain items={data.craft.entries} {...props} />}
  />
);
