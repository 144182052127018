import React from 'react';
import PropTypes from 'prop-types';
import { RowStyled } from './styles';

const Row = props => {
  const { children, className } = props;

  return <RowStyled className={className}>{children}</RowStyled>;
};

Row.propTypes = {};

Row.defaultProps = {};

export default Row;
