import React from 'react';
import { SvgStyled } from 'components/Icon/styles';

export default () => (
  <SvgStyled
    xmlns="http://www.w3.org/2000/svg"
    width="31.8"
    height="31.8"
    viewBox="0 0 31.8 31.8"
  >
    <path
      d="M31.8,15.9A15.9,15.9,0,1,1,0,15.9a15.12,15.12,0,0,1,.9-5.3A15.919,15.919,0,0,1,15.9,0a16.123,16.123,0,0,1,7.779,2.03A15.777,15.777,0,0,1,30.9,10.6,17.822,17.822,0,0,1,31.8,15.9Z"
      fill="#e03131"
    />
    <path
      d="M30.8,10.6H.9A15.919,15.919,0,0,1,15.9,0,15.693,15.693,0,0,1,30.8,10.6Z"
      fill="#272727"
    />
    <path d="M30.9,21.2a15.913,15.913,0,0,1-30,0Z" fill="#e2b111" />
  </SvgStyled>
);
