import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { parseAsContent } from 'utils/parser';
import { InfoBookingStyled, HeadingStyled, BookingStyled } from './styles';

function InfoBooking({ bookings }) {
  if (!bookings?.length) return null;

  return (
    <InfoBookingStyled>
      {bookings.map(item => (
        <BookingStyled key={item.id}>
          <HeadingStyled>{item.bookingTitle}</HeadingStyled>
          {parseAsContent(item.bookingInfo.content)}
        </BookingStyled>
      ))}
    </InfoBookingStyled>
  );
}

InfoBooking.propTypes = {
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      bookingTitle: PropTypes.string,
      bookingInfo: PropTypes.shape({
        content: PropTypes.string,
      }),
    }),
  ),
};

InfoBooking.defaultProps = {
  bookings: [],
};

export default props => (
  <StaticQuery
    query={graphql`
      query BookingQuery {
        craft {
          globals {
            booking {
              booking {
                id
                bookingTitle
                bookingInfo {
                  content
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <InfoBooking
        bookings={data?.craft?.globals?.booking?.booking}
        {...props}
      />
    )}
  />
);
