import styled from 'react-emotion';
import media from 'utils/mediaqueries';

export const HeaderStyled = styled('header')`
  position: absolute;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  color: white;
`;

export const NavigationSocialWrapperStyled = styled('div')`
  display: none;

  ${[media('md')]} {
    position: absolute;
    box-sizing: border-box;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    text-align: right;
  }
`;
