import { injectGlobal } from 'emotion';
import media from 'utils/mediaqueries';
import { fonts, sizes, colors } from './variables';
import 'normalize.css';

// Fonts
import HKGroteskBold from '../../assets/fonts/HKGrotesk-Bold.otf';
import HKGroteskMedium from '../../assets/fonts/HKGrotesk-Medium.otf';
import HKGroteskRegular from '../../assets/fonts/HKGrotesk-Regular.otf';
import HKGroteskItalic from '../../assets/fonts/HKGrotesk-Italic.otf';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  @font-face {
    font-family: 'HK Grotesk';
    src: url(${HKGroteskBold});
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'HK Grotesk';
    src: url(${HKGroteskRegular});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'HK Grotesk';
    src: url(${HKGroteskMedium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'HK Grotesk';
    src: url(${HKGroteskItalic});
    font-weight: normal;
    font-style: italic;
  }

  body {
    background-color: ${colors.white};
    color: ${colors.grey};
    font-family: ${fonts.primary};
    font-size: ${fonts.sizes.mobile};
    line-height: 1.7;
    font-weight: 500;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;

    ${[media('md')]} {
      font-size: ${fonts.sizes.base};
    }
  }

  .no-scroll {
    overflow: hidden !important;
  }

  ul {
    list-style-position: inside;
  }

  p {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: ${sizes.spacingHalf} 0;

    ${[media('md')]} {
      margin: ${sizes.spacingHalf} 0 ${sizes.spacingDouble};
    }
  }

  a {
    color: currentColor;
    text-decoration: underline;
    text-decoration-skip: ink;
  }

  h1, h2, h3, h4, h5, h6 {
    a {
      color: inherit!important;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  [role="button"],
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button {
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
  }

  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button {
    cursor: pointer;
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button; /* for input */
    -webkit-user-select: none; /* for button */
       -moz-user-select: none;
        -ms-user-select: none;
  }

  input::-moz-focus-inner,
  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  button:hover, button:focus {
    color: inherit;
  }
`;
