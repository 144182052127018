import React from 'react';
import PropTypes from 'prop-types';
import SwitchTheme from 'components/SwitchTheme';
import { LinkButtonStyled, IconStyled } from 'components/LinkButton/styles';

const LinkButton = (props) => {
  const { children, themeName, iconName, compact } = props;

  return (
    <SwitchTheme name={themeName}>
      <LinkButtonStyled
        compact={compact}
        icon={iconName && iconName.length > 0}
        themed={typeof themeName !== 'undefined'}
        {...props}
      >
        {iconName && <IconStyled name={iconName} />}
        {children}
      </LinkButtonStyled>
    </SwitchTheme>
  );
};

LinkButton.propTypes = {
  iconName: PropTypes.string,
};

LinkButton.defaultProps = {
  iconName: null,
};

export default LinkButton;
