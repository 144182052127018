import React from 'react';
import { SvgStyled } from 'components/Icon/styles';

export default () => (
  <SvgStyled xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M13.9,7.05a1.65,1.65,0,0,0-1-1A2.69,2.69,0,0,0,12,5.93c-.53,0-.69,0-2,0s-1.49,0-2,0a2.69,2.69,0,0,0-.93.17,1.68,1.68,0,0,0-1,1A2.69,2.69,0,0,0,5.93,8c0,.53,0,.68,0,2s0,1.49,0,2A2.69,2.69,0,0,0,6.1,13a1.68,1.68,0,0,0,1,1,2.69,2.69,0,0,0,.93.17c.53,0,.68,0,2,0s1.49,0,2,0A2.73,2.73,0,0,0,13,13.9a1.66,1.66,0,0,0,.94-1,2.7,2.7,0,0,0,.18-.93c0-.53,0-.69,0-2s0-1.49,0-2a2.7,2.7,0,0,0-.18-.93ZM10,12.57A2.57,2.57,0,1,1,12.57,10h0A2.57,2.57,0,0,1,10,12.57Zm2.67-4.64a.6.6,0,1,1,.6-.6.6.6,0,0,1-.6.6Z" />
    <circle cx="10" cy="10" r="1.67" />
    <path d="M10,0A10,10,0,1,0,20,10h0A10,10,0,0,0,10,0Zm5.18,12.15a3.86,3.86,0,0,1-.24,1.26,2.69,2.69,0,0,1-1.53,1.52,3.62,3.62,0,0,1-1.26.25c-.56,0-.73,0-2.15,0s-1.59,0-2.15,0a3.62,3.62,0,0,1-1.26-.25,2.69,2.69,0,0,1-1.53-1.52,3.86,3.86,0,0,1-.24-1.26c0-.56,0-.74,0-2.15s0-1.59,0-2.15a3.86,3.86,0,0,1,.24-1.26A2.69,2.69,0,0,1,6.59,5.07a3.62,3.62,0,0,1,1.26-.25c.56,0,.74,0,2.15,0s1.59,0,2.15,0a3.86,3.86,0,0,1,1.26.24,2.74,2.74,0,0,1,1.53,1.53,3.86,3.86,0,0,1,.24,1.26c0,.56,0,.73,0,2.15s0,1.59,0,2.15Z" />
  </SvgStyled>
);
