import React from 'react';
import styled from 'react-emotion';
import Link from 'components/Link';
import media from 'utils/mediaqueries';
import { fonts, sizes } from 'components/Layout/variables';

export const LinkStyled = styled(Link)`
  display: block;
  text-decoration: none;
  font-weight: bold;
  line-height: 1.4;
  font-size: 1.6em;
  padding: ${sizes.spacingQuarter} 0 ${sizes.spacingHalf};
  transition: transform 0.25s ease-in-out;

  ${[media('md')]} {
    padding: ${sizes.spacingQuarter} 0 ${sizes.spacingHalf};
  }

  &:hover {
    transform: translateX(15px);
  }
`;
