import React from 'react';
import PropTypes from 'prop-types';
import { GridStyled } from './styles';

const Grid = props => {
  const { children } = props;

  return <GridStyled>{children}</GridStyled>;
};

Grid.propTypes = {};

Grid.defaultProps = {};

export default Grid;
