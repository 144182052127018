import React from 'react';
import PropTypes from 'prop-types';
import { ColumnStyled } from './styles';

const Column = props => {
  const { width, children, display, flex, className } = props;

  // Map widths to breakpoints.
  const mappedWidths = Object.keys(width).map(breakpoint => ({
    breakpoint,
    width: width[breakpoint],
  }));

  return (
    <ColumnStyled
      display={display}
      flex={flex}
      mappedWidths={mappedWidths}
      className={className}
    >
      {children}
    </ColumnStyled>
  );
};

Column.propTypes = {
  width: PropTypes.shape({
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    xxl: PropTypes.string,
  }),
};

Column.defaultProps = {
  width: {
    sm: null,
    md: null,
    lg: null,
    xl: null,
    xxl: null,
  },
};

export default Column;
