import React from 'react';
import NavigationSocial from 'components/NavigationSocial';
import Wrapper from 'components/Wrapper';
import Navbar from 'components/Navbar';
import { HeaderStyled, NavigationSocialWrapperStyled } from './styles';

const Header = ({
  menuOpen,
  pathname,
  onBurgerClick,
  onArrowBackClick,
  onNavbarLinkClick,
}) => (
  <HeaderStyled>
    <Navbar
      menuOpen={menuOpen}
      pathname={pathname}
      onBurgerClick={onBurgerClick}
      onArrowBackClick={onArrowBackClick}
      onNavbarLinkClick={onNavbarLinkClick}
    />

    {pathname === '/' && (
      <NavigationSocialWrapperStyled>
        <Wrapper>
          <NavigationSocial format="icons" />
        </Wrapper>
      </NavigationSocialWrapperStyled>
    )}
  </HeaderStyled>
);

export default Header;
