import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { InfoCreditsStyled } from './styles';

const CreditTitle = ({ title }) => {
  if (title) return `${title}: `;
};

function InfoCredits({ credits }) {
  if (!credits?.length) return null;

  return (
    <InfoCreditsStyled>
      {credits.map(item => (
        <div key={item.id}>
          {item.creditLink ? (
            <a href={item.creditLink} target="_blank" rel="noopener noreferrer">
              <CreditTitle title={item.creditTitle} /> {item.creditText}
            </a>
          ) : (
            <div>
              <CreditTitle title={item.creditTitle} />
              {item.creditText}
            </div>
          )}
        </div>
      ))}
    </InfoCreditsStyled>
  );
}

InfoCredits.propTypes = {
  credits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      creditLink: PropTypes.string,
      creditTitle: PropTypes.string,
      creditText: PropTypes.string,
    }),
  ),
};

InfoCredits.defaultProps = {
  credits: [],
};

export default props => (
  <StaticQuery
    query={graphql`
      query CreditsQuery {
        craft {
          globals {
            credits {
              credits {
                id
                creditTitle
                creditText
                creditLink
              }
            }
          }
        }
      }
    `}
    render={data => (
      <InfoCredits
        credits={data?.craft?.globals?.credits?.credits}
        {...props}
      />
    )}
  />
);
