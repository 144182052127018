import React from 'react';
import { SvgStyled } from 'components/Icon/styles';

export default () => (
  <SvgStyled xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10s10-4.5,10-10C20,4.5,15.5,0,10,0z M15.6,8.6c-1,0.1-2-0.2-3-0.9v3.9
	c0,5-5.4,6.5-7.6,3c-1.4-2.3-0.5-6.3,3.9-6.5v2.2c-0.3,0.1-0.7,0.1-1,0.3c-1,0.3-1.6,1-1.4,2.1c0.3,2.1,4.2,2.8,3.9-1.4V3.5h2.2v0
	c0.2,1.9,1.3,3,3.1,3.1V8.6z"
    />
  </SvgStyled>
);
