import styled, { css } from 'react-emotion';

export const BurgerStyled = styled('div')({
  position: 'relative',
  flex: 'none',
  cursor: 'pointer',
  padding: 6,

  '&:focus, &:active': {
    WebkitTapHighlightColor: 'transparent',
  },
});

export const BurgerInnerStyled = styled('div')({
  position: 'relative',
  width: 20,
  height: 14,
});

// Active state (cross)
export const BurgerBarActiveStyles = css`
  // Top bar
  &:nth-child(1) {
    top: 6px;
    transform: rotate(45deg);
  }

  // Middle bar
  &:nth-child(2) {
    opacity: 0;
  }

  // Bottom bar
  &:nth-child(3) {
    top: 6px;
    transform: rotate(-45deg);
  }
`;

// Normal state (burger)
export const BurgerBarStyled = styled('span')`
  pointer-events: none;
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 2px;
  background-color: currentColor;
  transform-origin: 50% 50%;

  // Top bar
  &:nth-child(1) {
    top: 0px;
  }

  // Top bar
  &:nth-child(2) {
    opacity: 1;
    top: 6px;
  }

  // Bottom bar
  &:nth-child(3) {
    top: 12px;
  }

  // Active state
  ${props => (props.active ? BurgerBarActiveStyles : null)};
`;
