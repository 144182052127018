import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Icon from 'components/Icon';
import { LinkStyled, NavSocialStyled } from './styles';

const NavigationSocial = (props) => {
  const { items, format } = props;

  if (!items || items.length <= 0) {
    return null;
  }

  return (
    <NavSocialStyled icon={format === 'icons'}>
      {items.map((item) => (
        <LinkStyled
          key={item.id}
          to={item.socialNetworkUrl}
          icon={format === 'icons'}
          title={format === 'icons' ? item.socialNetworkTitle : ''}
        >
          {format === 'icons' ? (
            <Icon name={item.socialNetworkIcon} />
          ) : (
            item.socialNetworkTitle
          )}
        </LinkStyled>
      ))}
    </NavSocialStyled>
  );
};

NavigationSocial.propTypes = {
  format: PropTypes.oneOf(['text', 'icons']),
};

NavigationSocial.defaultProps = {
  format: 'text',
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query NavigationSocialQuery {
        craft {
          globals {
            socialNetworks {
              socialNetworks {
                id
                socialNetworkUrl
                socialNetworkIcon
                socialNetworkTitle
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <NavigationSocial
        items={data.craft.globals.socialNetworks.socialNetworks}
        {...props}
      />
    )}
  />
);
