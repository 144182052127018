import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import { sizes } from 'components/Layout/variables';

export const WrapperStyled = styled('div')(
  {
    position: 'relative',
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: '100%',
    width: '1600px',
    padding: `${sizes.spacingHalf} ${sizes.spacingHalf}`,

    [media('xs')]: {
      padding: `${sizes.spacingHalf} ${sizes.spacingHalf}`,
    },

    [media('sm')]: {
      padding: `${sizes.spacingDouble} ${sizes.spacingHalf}`,
    },

    [media('md')]: {
      padding: `${sizes.spacingDouble}`,
    },

    [media('xl')]: {
      padding: `${sizes.spacingDouble} ${sizes.spacingQuad}`,
    },
  },
  props =>
    props.padded && {
      [media('md')]: {
        paddingRight: `calc(25% - ${sizes.spacingQuad})`,
      },
    },
  props =>
    props.fluid && {
      padding: 0,
      width: '100%',

      [media('sm')]: {
        padding: 0,
      },

      [media('md')]: {
        padding: 0,
      },
    },
);
