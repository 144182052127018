import React from 'react';
import { withTheme } from 'emotion-theming';
import InfoCredits from 'components/InfoCredits';
import InfoBooking from 'components/InfoBooking';
import NavigationMeta from 'components/NavigationMeta';
import NavigationSocial from 'components/NavigationSocial';
import EventsUpcoming from 'components/EventsUpcoming';
import Wrapper from 'components/Wrapper';
import { Grid, Row, Column } from 'components/Grid';
import {
  FooterStyled,
  FooterHeadline,
  NavigationSocialWrapperStyled,
  SpacingStyled,
} from './styles';

const Footer = () => {
  return (
    <FooterStyled>
      <Wrapper>
        <Grid>
          <Row>
            <Column width={{ sm: '50%', lg: '30%' }}>
              <FooterHeadline element="h6">Navigation</FooterHeadline>
              <NavigationMeta />
            </Column>
            <Column width={{ sm: '50%', lg: '30%' }}>
              <FooterHeadline element="h6">Booking</FooterHeadline>
              <InfoBooking />
              <SpacingStyled />
              <NavigationSocialWrapperStyled>
                <FooterHeadline element="h6">Social Media</FooterHeadline>
                <NavigationSocial format="icons" />
              </NavigationSocialWrapperStyled>
            </Column>
            <Column display="flex" width={{ sm: '60%', lg: '40%' }}>
              <FooterHeadline element="h6">Anstehende Termine</FooterHeadline>
              <EventsUpcoming limit={3} />
              <InfoCredits />
            </Column>
          </Row>
        </Grid>
      </Wrapper>
    </FooterStyled>
  );
};

export default withTheme(Footer);
