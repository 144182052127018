import React from 'react';
import PropTypes from 'prop-types';
import { WrapperStyled } from './styles';

const Wrapper = props => {
  const { fluid, padded, className, children } = props;

  return (
    <WrapperStyled fluid={fluid} padded={padded} className={className}>
      {children}
    </WrapperStyled>
  );
};

Wrapper.propTypes = {
  padded: PropTypes.bool,
  fluid: PropTypes.bool,
};

Wrapper.defaultProps = {
  padded: false,
  fluid: false,
};

export default Wrapper;
