import React from 'react';
import { SvgStyled } from 'components/Icon/styles';

export default () => (
  <SvgStyled
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="6"
    viewBox="0 0 22 6"
  >
    <circle cx="3" cy="3" r="3" />
    <circle cx="11" cy="3" r="3" />
    <circle cx="19" cy="3" r="3" />
  </SvgStyled>
);
