import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';
import SwitchTheme from 'components/SwitchTheme';
import Favicons from 'components/Favicons';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CookieInfo from 'components/CookieInfo';
import './globalStyles';
import { StyledRouteContainer } from './styles';

class Layout extends Component {
  /**
   * Initial state.
   */
  state = {
    menuOpen: false,
    scrollEnabled: true,
  };

  /**
   * Event:
   * Toggle menu.
   */
  toggleMenu = () => {
    const { menuOpen, scrollEnabled } = this.state;

    this.setState({
      menuOpen: !menuOpen,
      scrollEnabled: !scrollEnabled,
    });
  };

  /**
   * Event:
   * Close menu.
   */
  closeMenu = () => {
    this.setState({
      menuOpen: false,
      scrollEnabled: true,
    });
  };

  /**
   * Event:
   * Handle button back click.
   */
  handleButtonBackClick = () => {
    navigate('/');
  };

  /**
   * Event:
   * Handle navigation main link click.
   */
  handleNavbarLinkClick = to => {
    this.closeMenu();
    navigate(to);
  };

  /**
   * Render.
   */
  render() {
    const { children, location } = this.props;
    const { menuOpen, scrollEnabled } = this.state;

    return (
      <>
        <Helmet>
          <html className={scrollEnabled ? 'scroll' : 'no-scroll'} lang="de" />
          <body className={scrollEnabled ? 'scroll' : 'no-scroll'} />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1.0, user-scalable=no"
          />
          <meta property="og:locale" content="de" />
        </Helmet>
        <Favicons />
        <SwitchTheme>
          <>
            <Header
              menuOpen={menuOpen}
              pathname={location?.pathname}
              onBurgerClick={this.toggleMenu}
              onArrowBackClick={this.handleButtonBackClick}
              onNavbarLinkClick={this.handleNavbarLinkClick}
            />
            <PoseGroup preEnterPose="entering">
              <StyledRouteContainer key={location?.pathname}>
                <main>{children}</main>
              </StyledRouteContainer>
            </PoseGroup>
            <Footer />
            <CookieInfo />
          </>
        </SwitchTheme>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
