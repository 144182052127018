import React from 'react';
import styled, { css } from 'react-emotion';
import Link from 'components/Link';
import { fonts } from 'components/Layout/variables';

export const NavigationMetaWrapper = styled('nav')({});

const NavigationMetaItemStyle = css({
  outline: 'none',
  display: 'block',
  textDecoration: 'none',
  fontSize: fonts.sizes.h4,
  fontWeight: 'bold',
  padding: '0 0 10px',
  margin: 0,
  lineHeight: 'inherit',
  transition: 'transform 0.25s ease-in-out',

  '&:hover': {
    transform: 'translateX(10px)',
  },
});

export const LinkStyled = styled(Link)(NavigationMetaItemStyle);

export const ButtonStyled = styled('button')(NavigationMetaItemStyle);
