const vars = require('components/Layout/variables');

// Default theme
module.exports = {
  colorPrimary: 'currentColor',
  // colorPrimaryHover
  // colorPrimaryActive
  // colorPrimaryDisabled
  backgroundPrimary: 'transparent',
  backgroundPrimaryActive: 'transparent',
  backgroundPrimaryHover: 'transparent',
  // backgroundPrimaryDisabled

  ...vars.sizes,
  fonts: { ...vars.fonts },

  // legacy -->
  grid: {
    gutterWidthHalf: '20px',
    gutterWidth: '40px',
    widthMax: '1600px',
  },
  footer: {
    fontSize: vars.fonts.sizes.small,
    colorBg: vars.colors.greyDark,
    color: vars.colors.greyLight,
    colorAlt: vars.colors.greyMediumAlt,
  },
  // <-- legacy
};
