import React from 'react';
import PropTypes from 'prop-types';
import { BurgerStyled, BurgerInnerStyled, BurgerBarStyled } from './styles';

export default function Burger(props) {
  const { active, onClick } = props;

  return (
    <BurgerStyled onClick={onClick} active={active}>
      <BurgerInnerStyled>
        <BurgerBarStyled active={active} />
        <BurgerBarStyled active={active} />
        <BurgerBarStyled active={active} />
      </BurgerInnerStyled>
    </BurgerStyled>
  );
}

Burger.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

Burger.defaultProps = {
  onClick: () =>
    console.log('Your burger seems to be missing a click handler.'),
  active: false,
};
