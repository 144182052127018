import React from 'react';
import PropTypes from 'prop-types';

export default function Text({ element, children }) {
  switch (element) {
    case 'span':
      return <span>{children}</span>;
    case 'p':
      return <p>{children}</p>;
    default:
      return <div>{children}</div>;
  }
}

Text.propTypes = {
  element: PropTypes.oneOf(['span', 'p', 'div']),
};

Text.defaultProps = {
  element: 'p',
};
