import React from 'react';
import styled from 'react-emotion';
import { sizes, fonts } from 'components/Layout/variables';

export const InfoBookingStyled = styled('div')(
  {
    fontWeight: 'bold',
    letterSpacing: '0.1em',
    lineHeight: '1.5',
    color: 'inherit',
  },
  props => ({
    fontSize: props.theme.footer.fontSize,
  }),
);

export const HeadingStyled = styled('b')({
  opacity: 0.5,
});

export const BookingStyled = styled('div')({
  margin: '5px 0 20px',
  display: 'inline-block',
  width: '100%',

  p: {
    margin: 0,
  },

  a: {
    textDecoration: 'none',
  },

  '&:last-child': {
    marginBottom: 0,
  },
});
