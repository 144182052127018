import React from 'react';
import { SvgStyled } from 'components/Icon/styles';

export default () => (
  <SvgStyled
    xmlns="http://www.w3.org/2000/svg"
    width="31.8"
    height="31.8"
    viewBox="0 0 31.8 31.8"
  >
    <path d="M31.8,15.9A15.9,15.9,0,0,1,0,15.9v-.3H31.8Z" fill="#e03131" />
    <path
      d="M31.8,15.6H0a14.26,14.26,0,0,1,.9-5A16.06,16.06,0,0,1,14.8,0H17a15.79,15.79,0,0,1,4.2.9,16.06,16.06,0,0,1,9.7,9.7A21,21,0,0,1,31.8,15.6Z"
      fill="#3a3aa5"
    />
    <path
      d="M15.2,10.6a1.68,1.68,0,0,0-1,.6,1.1,1.1,0,1,0-2.2,0,1.68,1.68,0,0,0-.7-.9,1.6,1.6,0,0,0-.8-.3h0a1.6,1.6,0,0,0-.8.3,2.43,2.43,0,0,0-.7.9A1,1,0,0,0,8,10a1.11,1.11,0,0,0-1.1,1.1,1.68,1.68,0,0,0-1-.6,1.45,1.45,0,0,0-1,.1l1.4,2.6h8.4l1.4-2.6A2.42,2.42,0,0,0,15.2,10.6Z"
      fill="#fec512"
    />
    <circle cx="10.6" cy="4.2" r="0.2" fill="#fec512" />
    <path d="M11.2,5.2A.2.2,0,1,0,11,5,.22.22,0,0,0,11.2,5.2Z" fill="#fec512" />
    <circle cx="9.9" cy="4.9" r="0.2" fill="#fec512" />
    <path
      d="M11.2,6.2a.6.6,0,0,0-1.2,0,.65.65,0,0,0,.6.6A.58.58,0,0,0,11.2,6.2Z"
      fill="#fec512"
    />
    <path d="M10.6,9.1a.2.2,0,1,0,.2.2A.22.22,0,0,0,10.6,9.1Z" fill="#fec512" />
    <circle cx="10.6" cy="8.4" r="0.2" fill="#fec512" />
    <circle cx="10.6" cy="7.6" r="0.2" fill="#fec512" />
    <path
      d="M9.6,7.5a.22.22,0,0,0,.2-.2c0-.1-.1-.3-.2-.3a.22.22,0,0,0-.2.2C9.4,7.3,9.5,7.5,9.6,7.5Z"
      fill="#fec512"
    />
    <circle cx="7.8" cy="6.8" r="0.2" fill="#fec512" />
    <circle cx="5.9" cy="7.1" r="0.2" fill="#fec512" />
    <circle cx="5" cy="8.8" r="0.2" fill="#fec512" />
    <circle cx="5.3" cy="9.9" r="0.2" fill="#fec512" />
    <path
      d="M6.9,7.1c.1,0,.2-.1.2-.3s-.1-.2-.2-.2a.22.22,0,0,0-.2.2C6.7,6.9,6.7,7.1,6.9,7.1Z"
      fill="#fec512"
    />
    <path
      d="M5.3,8.1a.2.2,0,1,0,0-.4c-.2-.1-.3,0-.3.2S5.1,8.1,5.3,8.1Z"
      fill="#fec512"
    />
    <circle cx="8.7" cy="7" r="0.2" fill="#fec512" />
    <circle cx="11.5" cy="7.2" r="0.2" fill="#fec512" />
    <path
      d="M13.3,7.1a.2.2,0,1,0-.2-.2A.22.22,0,0,0,13.3,7.1Z"
      fill="#fec512"
    />
    <path
      d="M15.2,7.4a.2.2,0,1,0,0-.4c-.1-.1-.2,0-.2.1S15.1,7.4,15.2,7.4Z"
      fill="#fec512"
    />
    <circle cx="16.1" cy="8.8" r="0.2" fill="#fec512" />
    <circle cx="15.9" cy="9.9" r="0.2" fill="#fec512" />
    <circle cx="14.3" cy="6.8" r="0.2" fill="#fec512" />
    <circle cx="15.9" cy="7.9" r="0.2" fill="#fec512" />
    <circle cx="12.4" cy="7" r="0.2" fill="#fec512" />
    <path
      d="M5.9,8.1a2,2,0,0,0,0,1.5,4.33,4.33,0,0,0,.1.5h.4a4.88,4.88,0,0,0-.3-.7,3.08,3.08,0,0,1-.1-1,.91.91,0,0,1,.3-.5,1.56,1.56,0,0,1,1.2-.3A5.42,5.42,0,0,1,9,7.8a.92.92,0,0,1,.6.4c.1.2.1.3.1.9v.6H10V8.4A.76.76,0,0,0,9.9,8c-.2,0-.3-.2-.9-.4a4,4,0,0,0-1.5-.3,1.56,1.56,0,0,0-1.2.3C6,7.8,5.9,8,5.9,8.1Z"
      fill="#fec512"
    />
    <path
      d="M11.3,8.1a.6.6,0,0,0-.1.4V9.8h.3V9.2c0-.6,0-.7.1-.9a.92.92,0,0,1,.6-.4,3.12,3.12,0,0,1,1.5-.2,1.59,1.59,0,0,1,1.2.4c.2.2.2.4.3.5a1.8,1.8,0,0,1-.1,1,4.88,4.88,0,0,0-.3.7h.4a1.69,1.69,0,0,1,.2-.5,2.55,2.55,0,0,0,0-1.5c-.1-.1-.2-.3-.4-.4a1.68,1.68,0,0,0-1.2-.3,5.42,5.42,0,0,0-1.5.2C11.6,7.8,11.4,8,11.3,8.1Z"
      fill="#fec512"
    />
  </SvgStyled>
);
