import React from 'react';
import { useScrollPosition } from 'utils/hooks/useScrollPosition';
import { NavbarStyled } from './styles';
import NavbarStickyMemo from './NavbarSticky';

const Navbar = props => {
  const { menuOpen } = props;
  const sticky = useScrollPosition() > 200;

  return (
    <NavbarStyled active={menuOpen}>
      <NavbarStickyMemo sticky={sticky} menuOpen={menuOpen} {...props} />
    </NavbarStyled>
  );
};

export default Navbar;
