import styled from 'react-emotion';
import Icon from 'components/Icon';
import Link from 'components/Link';

export const LinkButtonStyled = styled(Link)(
  {
    boxSizing: 'border-box',
    display: 'inline-block',
    textDecoration: 'none',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    fontSize: '1.2em',
  },
  (props) =>
    props.icon && {
      display: 'inline-flex',
      alignItems: 'center',
    },
  (props) =>
    props.themed && {
      fontSize: '1em',
      textTransform: 'uppercase',
      padding: '17px 44px',
      borderRadius: props.theme.radius,
    },
  (props) =>
    props.compact && {
      fontSize: '0.8em',
      padding: '12px 34px',
      textTransform: 'uppercase',
    },
  ({ theme }) => ({
    color: theme.colorPrimary,
    backgroundColor: theme.backgroundPrimary,

    '&:hover': {
      backgroundColor: theme.backgroundPrimaryHover,
    },

    '&:active, &:focus': {
      backgroundColor: theme.backgroundPrimaryActive,
    },
  }),
);

export const IconStyled = styled(Icon)((props) => ({
  marginRight: props.theme.spacingHalf,
}));
